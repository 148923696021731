import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Login } from '../models/login.model';
import { User, UserToken } from '../models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private httpClient: HttpClient) {

  }
  
  Login(loginModel :Login) {
    return this.httpClient.post<User>(environment.apiUrl + '/api/Account/Login',loginModel);
  }

  LoginUserModel(loginModel :Login) {
    return this.httpClient.post<UserToken>(environment.apiUrl + '/User/ValidateUser',loginModel);
  }

  Logout(id: string) {
    return this.httpClient.get(environment.apiUrl + '/api/Account/Logout' + id);
  }

}
