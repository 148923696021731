import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Project } from '../models/project.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private httpClient: HttpClient) {

  }

  GetAllProjects() {
    return this.httpClient.get<any>(environment.apiUrl + '/api/Project/GetAllProjects');
  }

  AddProject(project : Project) {
    return this.httpClient.post<any>(environment.apiUrl + '/api/Project/AddProject',project);
  }

  DeleteProject(id: string) {
    return this.httpClient.delete(environment.apiUrl + '/api/Project/DeleteProject/' + id);
  }

  BindProjectModel() {
    return this.httpClient.get<any>(environment.apiUrl + '/api/Project/BindProjectModel');
  }
}
