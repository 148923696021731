import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class httpInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header to request

    //Get Token data from local storage
    let tokenInfo = JSON.parse(localStorage.getItem('currentUser'));
    if (tokenInfo && tokenInfo.token) {
      request = request.clone({
          setHeaders: { 
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Content-Type',
              'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
              'Authorization': `Bearer ${tokenInfo.token}`
          }
      });
  } else {
      request = request.clone({
          setHeaders: { 
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': 'http://localhost:4200',
              'Access-Control-Allow-Headers': 'Content-Type',
              'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
          }
      });
  }


    // if (tokenInfo && tokenInfo.token) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: 'Bearer ' + tokenInfo.token,
    //     }
    //   });
    // }

    return newRequest.handle(request);
  }
}