import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//Modules
import { AuthGuard } from './helpers/auth.guard';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

//Components
import { AppComponent } from './app.component';
import { httpInterceptor } from './helpers/http-interceptor-extention';
import { UserService } from './services/user.service';
import { AccountService } from './services/account.service';
import { ProjectService } from './services/project.service';
import {dateFormatPipe} from '../app/helpers/datepipe';
import { DatePipe } from '@angular/common';
import { ErrorInterceptor } from './helpers/error.interceptor';

@NgModule({
  declarations: [
    AppComponent,dateFormatPipe
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CustomFormsModule,
    AppRoutingModule,
    NgMultiSelectDropDownModule.forRoot()
  ],exports:[

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true },
    AuthGuard,
    UserService,
    AccountService,
    ProjectService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
