import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { Company } from '../models/companymodel';
import { Agent } from '../models/agent.model';
import { Supplier, greyQuality } from '../models/supplier.model';
import { OutstandingSummary, OutstandingDetail } from '../models/outstandingSummaryModel';
import { StockSummary } from '../models/stockSummaryModel';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) {

  }

  GetAllUsers() {
    return this.httpClient.get<any>(environment.apiUrl + '/api/User/GetAllUsers');
  }

  GetUserById(id: string) {
    return this.httpClient.get<User>(environment.apiUrl + '/api/User/GetUserById/' + id);
  }

  CreateNewUser(user: User) {
    return this.httpClient.post<any>(environment.apiUrl + '/api/User/AddUser', user);
  }

  UpdateUser(user: User) {
    return this.httpClient.post<any>(environment.apiUrl + '/api/User/UpdateUser', user);
  }

  DeleteUser(id: string) {
    return this.httpClient.delete(environment.apiUrl + '/api/User/DeleteUser/' + id);
  }

  GetAllCompanies() {
    return this.httpClient.get<Company>(environment.apiUrl + '/Company');
  }

  GetAllAgents() {
    return this.httpClient.get<Agent>(environment.apiUrl + '/Agent');
  }

  GetSupplierById(agentId: number) {
    return this.httpClient.get<Supplier>(environment.apiUrl + '/Supplier/' + agentId);
  }

  GetOutstandingSummary(outstandingSumm: OutstandingSummary) {
    return this.httpClient.post<any>(environment.apiUrl + '/OutstandingSummary', outstandingSumm);
  }

  GetOutstandingDetail(outstandingDetail: OutstandingDetail) {
    return this.httpClient.post<any>(environment.apiUrl + '/OutstandingDetail', outstandingDetail);
  }

  GetStockSummary(stkSumm: StockSummary) {
    return this.httpClient.post<any>(environment.apiUrl + '/StockSummary/StockSummaryList/', stkSumm);
  }

  GetAllGreyQuality() {
    return this.httpClient.get<greyQuality>(environment.apiUrl + '/GreyQuality');
  }

}
