import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

//Modules
import { AuthGuard } from './helpers/auth.guard';

const routes: Routes = [
  { path: '', redirectTo:'dashboard', pathMatch:'full' },
  { path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) },
  { path: 'dashboard', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'outstanding', loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard] },
  { path: 'stock', loadChildren: () => import('./components/stock/stockSummary.module').then(m => m.stockModule), canActivate: [AuthGuard] },
  { path: 'projects', loadChildren: () => import('./components/project/project.module').then(m => m.ProjectModule), canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule { }
